import React, { useEffect } from 'react';
import NavBar from '../../components/NavBar/NavBar';
import './DrillerScreenStyle.scss';
import { ThemeProvider } from '@mui/material';
import { appTheme } from '../../styles/tema';
import { getstatusLogin } from '../../services/drillingMechanics';

const DrillerScreen: React.FC = () => {

    const whitecolor = "#FFF"

    // Utilizado apenas para validar status do usuario no backend
    const getSelecao = () => {
        getstatusLogin().then((res) => { });
    };

    useEffect(() => {
        getSelecao();
    }, []);

    return (
        <ThemeProvider theme={appTheme} >
            <div className="reports vh-100">
                <NavBar />
                <div className="d-flex text-white pl-3 py-1">
                    XPD {'>'} Driller Screen
                </div>

                <div className="iframe-container-driller">
                    <div className="iframe-wrapper">
                        <div className="iframe-description">PAE002</div>
                        <div className="iframe-content">
                            <iframe src="https://pae002.xpd.driller.rzxtecnologia.com.br/driller-react" className="pae" title="PAE002"></iframe>
                        </div>
                    </div>
                    <div className="iframe-wrapper">
                        <div className="iframe-description">PAE003</div>
                        <div className="iframe-content">
                            <iframe src="https://pae003.xpd.driller.rzxtecnologia.com.br/driller-react" className="pae" title="PAE003"></iframe>
                        </div>
                    </div>
                </div>

            </div>
        </ThemeProvider>
    )
}

export default DrillerScreen